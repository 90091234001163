
import React, { useEffect, useState } from "react";
import { useCreditRequest } from '../Context/useCreditRequest';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import {
    Box,
    Grid,
    Typography,
    Button,
    CircularProgress,
} from '@mui/material';
import { WhatsApp, Email, Sms } from '@mui/icons-material';
import CustomBox from "src/components/CustomBox";
import Iconify from '../components/iconify';
import TarsService from '../services/TarsService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NewInvitation from "./NewInvitation";
import dayjs from 'dayjs';

const CreditLimit = ({ handleChangeStep, handleCloseModal }) => {

    const [sending, setSending] = useState(false);
    const { getStorage, setStorage } = useCreditRequestAuth();
    const MySwal = withReactContent(Swal);
    let request = getStorage("request");

    const { CalculateCreditLimit } = TarsService();
    const [creditLimit, setCreditLimit] = useState(null);
    const [expirationCreditLimit, setExpirationCreditLimit] = useState("");
    let moneyLocale = Intl.NumberFormat('es-CO');
    const [openModalInvitation, setOpenModalInvitation] = useState(false);

    const initialInvitation = {
        posGroup: "", notificationType: "", documentType: request.cusDocumentType, document: request.cusDocument, phone: request.cusPhone, email: request.cusEmail, reference: "", amountTreatment: "", amountFinance: "", expirationDate: dayjs().add(15, 'days').format("DD-MM-YYYY"), expirationDateO: dayjs().add(15, 'days'), treatments: [],
        customerIsPatient: "",
        patients: []
    }

    const [invitationData, setInvitationData] = useState(initialInvitation);
    const [creditData, setcreditData] = useState(null);

    useEffect(() => {
        calculate();

    }, []);


    const goToIdentityValidation = () => {

        setStorage("request", {
            ...request,
            state: 14
        });

        handleChangeStep(14);
    }

    const calculate = () => {
        setSending(true)
        CalculateCreditLimit(request.IdCustomer).then(res => {
            setSending(false)
            if (res.status === 200) {
                let data = JSON.parse(res.data.data);
                setcreditData(data)
                if (data.EnabledCreditLimit && !data.CurrentCreditLimit) {

                    if (data.CreditLimit.ShowMessage) {

                        MySwal.fire({
                            html: data.CreditLimit.ResultDetail,
                            icon: 'warning',
                            showCancelButton: true,
                            cancelButtonText: "Cerrar",
                            confirmButtonText: "Reintentar"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                calculate()
                            }
                        })


                    }

                }
                else if (data.EnabledCreditLimit && data.CurrentCreditLimit) {

                    if (data.CreditLimit.ShowMessage) {
                        MySwal.fire({
                            html: data.CreditLimit.ResultDetail,
                            icon: data.CreditLimit.Result === "DEN" ? "warning" : 'success'
                        })
                    }


                    setCreditLimit(data.CreditLimit.CreditLimit)
                    setExpirationCreditLimit(data.CreditLimit.Result === "DEN" ? "" : "Recuerda que el cupo vence el " + data.CreditLimit.DateExpirationLabel)
                }
                else if (!data.EnabledCreditLimit && data.CurrentCreditLimit) {

                    if (data.CreditLimit.ShowMessage) {
                        MySwal.fire({
                            html: data.CreditLimit.ResultDetail,
                            icon: data.CreditLimit.Result === "DEN" ? "warning" : 'success'
                        })
                    }


                    setCreditLimit(data.CreditLimit.CreditLimit)
                    setExpirationCreditLimit(data.CreditLimit.Result === "DEN" ? "" : "Recuerda que el cupo vence el " + data.CreditLimit.DateExpirationLabel)
                }
            }
            else {
                MySwal.fire({
                    html: res.data.message,
                    icon: 'error',
                    showCancelButton: true,
                    cancelButtonText: "Cerrar",
                    confirmButtonText: "Reintentar",
                    confirmButtonColor: "#00a9eb"
                }).then((result) => {
                    if (result.isConfirmed) {
                        calculate()
                    }
                })
            }

        })
    }

    return <>

        <NewInvitation
            isUpdate={false}
            openModal={openModalInvitation}
            setOpenModal={setOpenModalInvitation}
            invitationData={invitationData}
            setInvitationData={setInvitationData}
            initialInvitation={initialInvitation}
        />

        <CustomBox
            sx={{
                width: '80%'
            }}
        >
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right", marginTop: "0px", paddingTop: "0px" }}>
                <button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "0px" }} onClick={handleCloseModal} ><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button>

            </Grid>
            <Box
                sx={{
                    maxWidth: { xs: '100%', md: '80%' },
                    margin: '0 auto',
                    textAlign: 'center',
                    padding: { xs: '0px', md: '20px' },
                }}
            >

                <Typography variant="subtitle1" color="black" sx={{ fontSize: '13px', padding: '0px' }}>
                    {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ fontSize: '13px', padding: '0px' }}>
                    {request.cusDocumentTypeTemp} {request.cusDocument}
                </Typography>

                {!sending && <>
                    <Grid item xs={12}>
                        <Typography variant="h2" style={{ marginTop: "50px" }}>
                            Cupo disponible
                        </Typography>
                    </Grid>


                    <Grid item xs={12}>
                        <Typography variant="h3">
                            $ {moneyLocale.format(creditLimit)}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <p style={{ color: "gray" }}>
                            {expirationCreditLimit}
                        </p>
                    </Grid>


                    {creditData && creditData.CurrentCreditLimit && creditData.CustomerPendingValidation && <Grid container spacing={2}>

                        <Grid item xs={12} sm={12} md={12} xl={12} textAlign="center" style={{ marginTop: "50px" }}>
                            {creditData && creditData.CurrentCreditLimit && creditData.CreditLimit.Result === "APP" && <Button variant="contained" color="success" disabled={sending} onClick={goToIdentityValidation}>
                                Validar identidad
                            </Button>}

                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <p style={{ color: "gray" }}>
                                    Recuerda que para que el cliente pueda aceptar una invitación debe haber validado su identidad previamente
                                </p>
                            </Grid>

                            {/* <Button variant="contained" color="primary" disabled={sending} onClick={() => { setOpenModalInvitation(true); }}>Crear invitación</Button> */}
                        </Grid>


                    </Grid>}

                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={12} md={12} xl={12} textAlign="center" style={{ marginTop: "50px" }}>
                            {creditData && creditData.CurrentCreditLimit && creditData.CreditLimit.Result === "APP" && <Button variant="contained" color="primary" disabled={sending} onClick={() => { setOpenModalInvitation(true); }}>
                                Crear invitación
                            </Button>}
                            {/* <Button variant="contained" color="primary" disabled={sending} onClick={() => { setOpenModalInvitation(true); }}>Crear invitación</Button> */}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} xl={12} textAlign="center" style={{ marginTop: "20px" }}>

                            <Button
                                variant="outlined"
                                color="secondary"

                                disabled={sending}
                                onClick={handleCloseModal}
                                style={{ textDecoration: 'none' }}
                            >
                                Cerrar
                            </Button>
                        </Grid>


                    </Grid>
                </>}

                {/* Loader */}
                {sending && (
                    <Grid item xs={12} textAlign="center" style={{ marginTop: "50px" }}>
                        <CircularProgress />
                    </Grid>
                )}

            </Box>
        </CustomBox>
    </>
}

export default CreditLimit;