import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { RefreshToken } from '../services/SoraService'
import TarsService from '../services/TarsService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const ProtectedRoutes = ({ children }) => {
    const { GetAgoraVersion } = TarsService();
    const token = localStorage.getItem("token");
    const expiredTime = localStorage.getItem("expiredTime");
    const remember = localStorage.getItem("remember");
    const location = useLocation();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const VERSION = process.env.REACT_APP_AGORA_VERSION;


    GetAgoraVersion().then(res => {
        
        var data = JSON.parse(res.data.data);

        if (VERSION !== data.Version)

            MySwal.fire({
                title: 'Actualiza la página',
                html: 'Por favor actualiza la página oprimiendo Ctrl + F5 si estas en un computador o arrastrando la pantalla hacia abajo si estas en un dispositivo móvil)',
                icon: 'info',
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonText: "Contactanos",
                allowEnterKey: false,
                allowEscapeKey: false,
                confirmButtonColor: "#00a9eb",
                customClass: {
                    container: 'swal2-container',
                },
            }).then(result => {
               
                if (!result.isConfirmed) {
                    window.open("https://wa.me/" + encodeURI("+573012051542"))
                }

                window.location.reload(true);

            });


    });




    if (expiredTime && new Date().getTime() > expiredTime) {

        if (remember === "1") {

            RefreshToken({ AccessToken: localStorage.getItem("token"), RefreshToken: localStorage.getItem("refreshToken") }).then(res => {

                if (res.status === 200) {
                    const data = res.data.data
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("refreshToken", data.refreshToken);
                    localStorage.setItem("expiredTime", Date.parse(data.expiredTime));
                    localStorage.setItem("userName", data.userName);

                    navigate(location, { replace: true });
                } else {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expiredTime");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("fullName");
                    localStorage.removeItem("email");
                    localStorage.removeItem("phone");
                    localStorage.removeItem("avatar");
                    localStorage.removeItem("firstLogin");

                    localStorage.removeItem("remember");
                    navigate('/', { replace: true });
                }

            })
        }
        else {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expiredTime");
            localStorage.removeItem("userName");
            localStorage.removeItem("fullName");
            localStorage.removeItem("email");
            localStorage.removeItem("phone");
            localStorage.removeItem("avatar");
            localStorage.removeItem("firstLogin");
            localStorage.removeItem("remember");
            return <Navigate to="/" replace />
        }

        return <>
            <LinearProgress />
        </>

    }
    if (token && (location.pathname === "/" || location.pathname === "/restore-password") && new Date().getTime() < expiredTime) {
        return <Navigate to="/app" exact />
    }
    if (!token && location.pathname === "/") {
        return children
    }
    if (!token && location.pathname === "/restore-password") {
        return children
    }

    return token && new Date().getTime() < expiredTime ? (children || <Outlet />) : <Navigate to="/" replace />
};