import React, { useState, useEffect } from "react";
import {
    Modal,
    Grid
} from '@mui/material';
import NewCustomerS1 from './NewCustomerS1';
import NewCustomerS2 from './NewCustomerS2';
import Iconify from '../components/iconify';
import NewCustomerS3_1 from "./NewCustomerS3_1";
import NewCustomerS3_2 from "./NewCustomerS3_2";
import NewCustomerS4 from "./NewCustomerS4";
import IdentityValidation from "./IdentityValidation";
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import CreditLimit from "./CreditLimit";
import QuestionsVerification from "./QuestionVerification";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TarsService from '../services/TarsService'

const NewCustomerModal = ({ openModalNC, setOpenModalNC }) => {

    const [Steps, setSteps] = useState([true, false, false, false, false, false, false, false]);
    // const [Steps, setSteps] = useState([false, false, false, false, false, true, false, false]);
    const { clearRequest } = useCreditRequestAuth();
    const MySwal = withReactContent(Swal);
    const VERSION = process.env.REACT_APP_AGORA_VERSION;
    const { GetAgoraVersion } = TarsService();

    useEffect(() => {

        if (openModalNC)
            GetAgoraVersion().then(res => {

                var data = JSON.parse(res.data.data);

                if (VERSION !== data.Version)

                    MySwal.fire({
                        title: 'Actualiza la página',
                        html: 'Por favor actualiza la página oprimiendo Ctrl + F5 si estas en un computador o arrastrando la pantalla hacia abajo si estas en un dispositivo móvil)',
                        icon: 'info',
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        showCancelButton: true,
                        cancelButtonText: "Contactanos",
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        confirmButtonColor: "#00a9eb",
                        customClass: {
                            container: 'swal2-container',
                        },
                    }).then(result => {

                        if (!result.isConfirmed) {
                            window.open("https://wa.me/" + encodeURI("+573012051542"))
                        }

                        window.location.reload(true);

                    });


            });

        if (!openModalNC) return;

        function beforeUnload(e) {
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [openModalNC]);

    const handleCloseModal = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;

        let request = JSON.parse(sessionStorage.getItem("request"));
        if (request) {
            MySwal.fire({
                html: "¿Estás seguro de finalizar el proceso actual?",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: "Continuar proceso",
                confirmButtonText: "Finalizar proceso",
                cancelButtonColor: "#00a9eb",
                confirmButtonColor: "grey"
            }).then((result) => {

                if (result.isConfirmed) {


                    if (request?.state === 9) {
                        clearRequest();
                    }

                    setSteps([true, false, false, false, false, false, false, false])
                    setOpenModalNC(false)
                }

            })
        } else {
            setSteps([true, false, false, false, false, false, false, false])
            setOpenModalNC(false)
        }
    }


    let interval = null;



    useEffect(() => {


        interval = setInterval(() => {

            const expiredTime = sessionStorage.getItem("expiredTime")

            if (expiredTime) {

                if (expiredTime < new Date().getTime()) {

                    setSteps([true, false, false, false, false, false, false, false])
                    clearRequest();
                }
            }
            else {
                setSteps([true, false, false, false, false, false, false, false])
                clearRequest();
            }


        }, 1000 * 5);




    }, []);


    useEffect(() => {

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleChangeStep = (step) => {
        let request = JSON.parse(sessionStorage.getItem("request"));


        const newSteps = [false, false, false, false, false, false, false, false];
        if (request.state === 0)//INICIO
            newSteps[0] = true;

        if (request.state === 1)// NOMBRES Y CELULAR
            newSteps[1] = true;

        if (request.state === 2)//A DONDE ENVIAR OTP
            newSteps[2] = true;

        if (request.state === 3)//OTP ENVIADO
            newSteps[3] = true;

        if (request.state === 4)//PROFILE
            newSteps[4] = true;

        if (request.state === 14)//VALIDACION DE IDENTIDAD
            newSteps[5] = true;

        if (request.state === 13)//EVIDENTE
            newSteps[6] = true;

        if (request.state === 9)//CUPO
            newSteps[7] = true;

        setSteps(newSteps);
    }

    return (
        <>
            <Modal
                open={openModalNC}
                onClose={handleCloseModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{
                    overflow: 'scroll',
                }}
            >
                <>
                    {Steps[0] ?
                        <NewCustomerS1 handleChangeStep={handleChangeStep} handleCloseModal={handleCloseModal} />
                        :
                        Steps[1] ?
                            <NewCustomerS2 handleChangeStep={handleChangeStep} handleCloseModal={handleCloseModal}></NewCustomerS2>
                            :
                            Steps[2] ?
                                <NewCustomerS3_1 handleChangeStep={handleChangeStep} handleCloseModal={handleCloseModal}></NewCustomerS3_1>
                                :
                                Steps[3] ?
                                    <NewCustomerS3_2 handleChangeStep={handleChangeStep} handleCloseModal={handleCloseModal}></NewCustomerS3_2>
                                    :
                                    Steps[4] ?
                                        <NewCustomerS4 handleChangeStep={handleChangeStep} handleCloseModal={handleCloseModal}></NewCustomerS4>
                                        :
                                        Steps[5] ?
                                            <IdentityValidation handleChangeStep={handleChangeStep} handleCloseModal={handleCloseModal}></IdentityValidation>
                                            :
                                            Steps[6] ?
                                                <QuestionsVerification handleChangeStep={handleChangeStep} handleCloseModal={handleCloseModal}></QuestionsVerification>
                                                :
                                                Steps[7] ?
                                                    <CreditLimit handleChangeStep={handleChangeStep} handleCloseModal={handleCloseModal}></CreditLimit>
                                                    :
                                                    <></>
                    }
                </>
            </Modal>
        </>

    );
}


// function upFirstLetter(str) {
//     const arr = str.split(" ");
//     for (var i = 0; i < arr.length; i++) {
//         arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
//     }
//     const str2 = arr.join(" ");
//     return str2;
// };

// const defaultMaskOptions = {
//     prefix: '$',
//     suffix: '',
//     includeThousandsSeparator: true,
//     thousandsSeparatorSymbol: '.',
//     allowDecimal: true,
//     decimalSymbol: ',',
//     decimalLimit: 2, // how many digits allowed after the decimal
//     //   integerLimit: 7, // limit length of integer numbers
//     allowNegative: false,
//     allowLeadingZeroes: false,
// }

// const currencyMask = createNumberMask(defaultMaskOptions)

export default NewCustomerModal;